import React, {useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import fp from 'lodash/fp';
import {Formik, Form} from 'formik';

import {Button, makeStyles} from '@material-ui/core';

import {useApi} from '@arborian/narrf';

import {url_for} from 'ccm/routes';
import {fieldId} from 'ccm/lib/util';

import DynaField from './DynaField';
import {buildSchema} from './schemas';

export {default as ExpansionPanel} from './ExpansionPanel';

const useStyles = makeStyles(theme => ({
    formContainer: {
        maxWidth: 800,
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    formTitle: {
        textAlign: 'center',
    },
    formShape: {
        flex: 1,
    },
    buttonBar: {
        textAlign: 'center',
    },
}));

export default function DynaForm({formPage, session}) {
    const api = useApi();
    const history = useHistory();
    const classes = useStyles();
    const formDef = useMemo(() => {
        console.log('Create formDef', {formPage, session});
        if (!formPage || !session) return {};
        let initialValues = fp.pipe([
            fp.get('attributes.page.fields'),
            fp.map(f => [f.id, f.initialValue]),
            fp.fromPairs,
        ])(formPage);
        initialValues = fp.assign(
            initialValues,
            fp.get('attributes.session_data', session),
        );
        return {
            title: fp.get('attributes.page.title', formPage),
            fields: fp.get('attributes.page.fields', formPage),
            schema: buildSchema(fp.get('attributes.page', formPage)),
            initialValues,
        };
    }, [formPage, session]);
    const submitHandler = async (data, formikProps) => {
        console.log('SUBMIT');
        const session_data = fp.get('attributes.session_data', session);
        data['last_viewed'] = formPage.id;
        const updateDoc = {
            id: session.id,
            type: session.type,
            attributes: {
                ...fp.get('attributes', session),
                completed: fp.uniq([
                    ...fp.get('attributes.completed', session),
                    formPage.id,
                ]),
                session_data: fp.assign(session_data, data),
            },
        };
        let resp = await api.fetchJson(session.links.self, {
            method: 'PUT',
            json: {data: updateDoc},
        });
        const nextPage = fp.get('data.attributes.next_page', resp);
        if (nextPage === 'complete') {
            const patientTicketForm = '2'; // We skip the fax page for patient tickets
            const exitFlowPage =
                fp.get('attributes.formid', session) === patientTicketForm
                    ? 'formSubmit'
                    : 'formFax';
            const nextPageUrl = url_for(exitFlowPage, {sessionid: session.id});
            history.push(nextPageUrl);
        } else {
            const nextPageUrl = url_for('dynaform', {
                sessionid: session.id,
                formid: resp.data.attributes.formid,
                pageid: nextPage,
            });
            history.push(nextPageUrl);
        }
    };
    if (!formDef || !session || !formPage) return null;
    return (
        <Formik
            initialValues={formDef.initialValues}
            enableReinitialize={true}
            validationSchema={formDef.schema}
            onSubmit={submitHandler}
        >
            {({errors, handleSubmit, handleReset}) => (
                <div className={classes.formContainer}>
                    <h4 className={classes.formTitle}>{formDef.title}</h4>
                    <Form className={classes.formShape}>
                        {fp.map(
                            field => (
                                <DynaField
                                    key={field.id}
                                    field={field}
                                    sessionId={session.id}
                                />
                            ),
                            formDef.fields,
                        )}
                        {/* Debug Form Errors */}
                        {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                        <div className={classes.buttonBar}>
                            <Button
                                id={fieldId('dyn', 'backButton')}
                                onClick={() => history.go(-1)}
                            >
                                Back
                            </Button>
                            <Button
                                id={fieldId('dyn', 'nextButton')}
                                style={{margin: 20}}
                                variant='contained'
                                onClick={handleSubmit}
                            >
                                Next
                            </Button>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    );
}
