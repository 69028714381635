import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import * as R from 'react-router-dom';
import fp from 'lodash/fp';

import {components} from '@arborian/narrf';
import {Avatar, Tooltip, Chip, makeStyles} from '@material-ui/core';
import {
    Assignment,
    AssignmentTurnedIn,
    AssignmentLate,
    Archive,
    Block,
    ErrorOutline,
    FilterList,
    Flag,
    Inbox,
    Publish,
    Print,
    PrintDisabled,
} from '@material-ui/icons';

import {ducks} from '@arborian/narrf';
import {url_for} from '../routes';
import {useGlobals} from 'ccm/components/Globals';
import {useTickets, useMyTickets} from 'ccm/dataSource';
import MultiStateAvatar from 'ccm/components/MultiStateAvatar';
import {useTicketActions} from 'ccm/components/ticket';
import {
    selectAsList,
    selectTicketOwners,
    selectHealthcareProviders,
} from 'ccm/lib/selectors';

import {FacilityColumn} from 'ccm/components/columns';

import AsyncDialogFilter from 'ccm/components/datatable/AsyncDialogFilter';
import {useDialog} from 'ccm/components/dialogs';
import ProgressNoteUploadDialog from 'ccm/components/dialogs/ProgressNoteUploadDialog';
import TicketTypeDialog from 'ccm/components/dialogs/TicketTypeDialog';
import {useAllTicketTypesLookup} from 'ccm/dataSource/allResources';

const {dt2} = components;

const useStyles = makeStyles(theme => ({
    hbox: {
        display: 'flex',
        '& .MuiAvatar-root': {
            margin: theme.spacing(0, 0.25),
            width: theme.spacing(3),
            height: theme.spacing(3),
        },
        '& .MuiSvgIcon-root': {
            fontSize: '1rem',
        },
    },
    chipMargin: {
        margin: '12px -10px',
    },
    pccGreen: {
        color: '#fff',
        backgroundColor: '#8EC428',
        '& .MuiChip-icon': {
            color: 'white',
        },
    },
    pfBlue: {
        color: '#fff',
        backgroundColor: '#00A3FB',
        '& .MuiChip-icon': {
            color: 'white',
        },
    },
    success: {
        backgroundColor: theme.palette.success.main,
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
    },
    error: {
        backgroundColor: theme.palette.error.main,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
    },
}));

/*
const attrName = col => col.field.split('.').slice(2).join('.');
const fieldName = field => field.split('.').slice(1).join('.');

const exactFilterFormatter = (field, value) => {
    const fname = fieldName(field);
    return [fname, JSON.stringify(value)];
};
*/

const PENDING_RESOLVED = {$in: ['pending', 'resolved']};

const useTicketStatuses = (classes, options = {}) => {
    const result = useMemo(
        () => [
            {
                title: 'Pending/Resolved',
                value: PENDING_RESOLVED,
                Icon: AssignmentLate,
                className: classes.warning,
            },
            {
                title: 'Pending',
                value: 'pending',
                Icon: AssignmentLate,
                className: classes.error,
            },
            {
                title: 'Resolved',
                value: 'resolved',
                Icon: Assignment,
                className: classes.warning,
            },
            {
                title: 'Acknowledged',
                value: 'acknowledged',
                Icon: AssignmentTurnedIn,
                className: classes.success,
            },
        ],
        [classes],
    );

    if (options.filterTitle) {
        return [
            {
                title: options.filterTitle,
                Icon: FilterList,
            },
            ...result,
        ];
    } else {
        return result;
    }
};

const useProgressNoteStatuses = (classes, options = {}) => {
    const result = useMemo(
        () => [
            {
                title: 'No progress notes uploaded',
                value: 'none',
                Icon: Block,
                className: classes.error,
            },
            {
                title: 'Some progress notes uploaded',
                value: 'partial',
                Icon: Publish,
                className: classes.warning,
            },
            {
                title: 'All progress notes uploaded',
                value: 'complete',
                Icon: Publish,
                className: classes.success,
            },
        ],
        [classes],
    );

    if (options.filterTitle) {
        return [
            {
                title: options.filterTitle,
                Icon: FilterList,
            },
            ...result,
        ];
    } else {
        return result;
    }
};

const useFaxStatuses = (classes, options = {}) => {
    const result = useMemo(
        () => [
            {
                title: 'Unknown fax status (no numbers)',
                value: 'unknown',
                Icon: ErrorOutline,
                className: classes.error,
            },
            {
                title: 'No faxes sent',
                value: 'none',
                Icon: PrintDisabled,
                className: classes.error,
            },
            {
                title: 'Some faxes sent',
                value: 'partial',
                Icon: Print,
                className: classes.warning,
            },
            {
                title: 'All faxes sent',
                value: 'complete',
                Icon: Print,
                className: classes.success,
            },
        ],
        [classes],
    );
    if (options.filterTitle) {
        return [
            {
                title: options.filterTitle,
                Icon: FilterList,
            },
            ...result,
        ];
    } else {
        return result;
    }
};

function UserFilter({column, filter, onChangeFilter, selector, ...props}) {
    const globals = useGlobals();
    const fetchOptions = () => globals.fetchAll('user.Summaries');
    return (
        <AsyncDialogFilter
            column={column}
            filter={filter}
            onChangeFilter={onChangeFilter}
            selector={selector}
            fetchOptions={fetchOptions}
            {...props}
        />
    );
}

function SubmitterFilter(props) {
    const selector = fp.pipe([
        selectAsList('UserSummary'),
        fp.sortBy('attributes.reversed_name'),
        fp.map('attributes.reversed_name'),
    ]);
    return (
        <UserFilter freeSolo title='Submitter' selector={selector} {...props} />
    );
}

function OwnerFilter(props) {
    const selector = fp.pipe([
        selectTicketOwners,
        fp.sortBy('attributes.name'),
        fp.map('attributes.name'),
    ]);
    return <UserFilter title='Owner' selector={selector} {...props} />;
}

function ProviderFilter(props) {
    const selector = fp.pipe([
        selectHealthcareProviders,
        fp.sortBy('attributes.name'),
        fp.map('attributes.name'),
    ]);
    return <UserFilter title='Provider' selector={selector} {...props} />;
}

function BooleanState({
    value,
    trueClass,
    trueTitle,
    trueIcon,
    falseClass,
    falseTitle,
    falseIcon,
    ...props
}) {
    var title, className, TheIcon;
    if (value === true) {
        title = trueTitle;
        className = trueClass;
        TheIcon = trueIcon;
    } else {
        title = falseTitle;
        className = falseClass;
        TheIcon = falseIcon;
    }
    return (
        <Tooltip title={title}>
            <Avatar className={className} {...props}>
                <TheIcon />
            </Avatar>
        </Tooltip>
    );
}

function Status({rowData, ...props}) {
    const classes = useStyles();
    const ticketStatuses = useTicketStatuses(classes);
    const pnStatuses = useProgressNoteStatuses(classes);
    const faxStatuses = useFaxStatuses(classes);

    return (
        <div className={classes.hbox}>
            <MultiStateAvatar
                value={fp.get('data.attributes.data.status', rowData)}
                states={ticketStatuses}
                {...props}
            />
            <MultiStateAvatar
                value={fp.get('data.attributes.progress_note_status', rowData)}
                states={pnStatuses}
                {...props}
            />
            <BooleanState
                trueTitle='archived'
                falseClass={classes.success}
                falseTitle='active'
                trueIcon={Archive}
                falseIcon={Inbox}
                value={fp.get('data.attributes.archived', rowData)}
                {...props}
            />
            <MultiStateAvatar
                value={fp.get('data.attributes.fax_status', rowData)}
                states={faxStatuses}
                {...props}
            />
        </div>
    );
}

function StatusFilter({column, filter, onChangeFilter}) {
    const classes = useStyles();
    const ticketStatuses = useTicketStatuses(classes, {
        filterTitle: 'filter on ticket status',
    });
    const pnStatuses = useProgressNoteStatuses(classes, {
        filterTitle: 'filter on progress note status',
    });
    const faxStatuses = useFaxStatuses(classes, {
        filterTitle: 'filter on fax status',
    });

    const fields = {
        status: {
            field: 'attributes.data.status',
            options: ['resolved', 'pending'],
        },
        has_progressNotes: {
            field: 'attributes.has_progressNotes',
            options: [true, false],
        },
    };
    fp.forEach(f => {
        const curValue = fp.get(f.field, filter);
        const curIndex = fp.findIndex(fp.isEqual(curValue), f.options);
        f.value = [true, false][curIndex];
    }, fields);

    const toggleArchived = () => {
        const curValue = fp.get('attributes.archived', filter);
        onChangeFilter('attributes.archived', !curValue);
    };

    return (
        <div className={classes.hbox} id='status_filter'>
            <MultiStateAvatar
                id='statusStatus'
                value={fp.get('attributes.data.status', filter)}
                states={ticketStatuses}
                onChange={value =>
                    onChangeFilter('attributes.data.status', value)
                }
            />
            <MultiStateAvatar
                id='progressNoteStatus'
                value={fp.get('attributes.progress_note_status', filter)}
                states={pnStatuses}
                onChange={value =>
                    onChangeFilter('attributes.progress_note_status', value)
                }
            />
            <BooleanState
                trueTitle='archived'
                falseClass={classes.success}
                falseTitle='active'
                trueIcon={Archive}
                falseIcon={Inbox}
                id='archivedStatus'
                value={fp.get('attributes.archived', filter)}
                onClick={toggleArchived}
            />
            <MultiStateAvatar
                id='faxStatus'
                value={fp.get('attributes.fax_status', filter)}
                states={faxStatuses}
                onChange={value =>
                    onChangeFilter('attributes.fax_status', value)
                }
            />
        </div>
    );
}

const ticketOptions = {
    include: [
        // 'practice',
        // 'practice.ehr_connection',
        // 'patient',
        // 'patient.ehr_connection',
    ],
};

const TICKET_INITIAL_FETCH_OPTIONS = {
    sort: {field: 'attributes.data.created', direction: 'desc'},
    filter: {
        'attributes.archived': false,
        'attributes.data.status': PENDING_RESOLVED,
    },
    page: {number: 0, size: 20},
};

export default function TicketsTable({tab}) {
    const mergeFetchOptions = useMemo(() => {
        switch (tab) {
            case 'all':
                return {
                    filter: {
                        'attributes.data.patient_id': {$ne: null},
                    },
                };
            case 'unassigned':
                return {
                    filter: {
                        'attributes.data.patient_id': null,
                    },
                };
            default:
                return {};
        }
    }, [tab]);

    const allTickets = useTickets({
        //datasource
        options: ticketOptions,
        initialFetchOptions: TICKET_INITIAL_FETCH_OPTIONS,
        mergeFetchOptions,
    });
    const myTickets = useMyTickets({
        //datasource
        options: ticketOptions,
        initialFetchOptions: TICKET_INITIAL_FETCH_OPTIONS,
        mergeFetchOptions,
    });
    const tickets = tab === 'my' ? myTickets : allTickets;

    const ticketActions = useTicketActions();
    const uploadProgressNoteDialog = useDialog(ProgressNoteUploadDialog);
    const ticketTypeSelect = useDialog(TicketTypeDialog);

    const refresh = () => tickets.fetch();
    const ticketTypes = useAllTicketTypesLookup();

    const userinfo = useSelector(ducks.auth.selectUserinfo);
    const isSuperuser = userinfo && fp.includes('__admin__', userinfo.scopes);

    const handleBulkArchive = async rows => {
        const batch = ticketActions.batch({
            success_message: 'Tickets archived successfully',
            error_message: 'Some tickets failed to archive',
        });
        const promises = fp.pipe([
            fp.filter(row => {
                if (row.data.attributes.archived) {
                    batch.error(`Ticket ${row.data.id} already archived`);
                    return false;
                }
                if (
                    row.data.attributes.data.status !== 'acknowledged' &&
                    !isSuperuser
                ) {
                    batch.error(
                        `Ticket ${row.data.id} cannot be archived while un-acknowledged`,
                    );
                    return false;
                }
                return true;
            }),
            fp.map(row => batch.archive(row.data)),
        ])(rows);
        await Promise.all(promises);
        await batch.flush();
        refresh();
    };

    const handleBulkUnarchive = async rows => {
        const batch = ticketActions.batch({
            success_message: 'Tickets un-archived successfully',
            error_message: 'Some tickets failed to un-archive',
        });
        const promises = fp.pipe([
            fp.filter(row => {
                if (!row.data.attributes.data.orders) {
                    batch.error(`Ticket ${row.data.id} is already active`);
                    return false;
                }
                return true;
            }),
            fp.map(row => batch.unarchive(row.data)),
        ])(rows);
        await Promise.all(promises);
        await batch.flush();
        refresh();
    };

    const handleBulkUrgent = async rows => {
        const batch = ticketActions.batch({
            success_message: 'Tickets updated successfully',
            error_message: 'Some tickets failed to update',
        });
        const promises = fp.pipe([fp.map(row => batch.toggleUrgent(row.data))])(
            rows,
        );
        await Promise.all(promises);
        await batch.flush();
        refresh();
    };

    const handleBulkRoute = async rows => {
        const batch = ticketActions.batch({
            success_message: 'Tickets re-routed successfully',
            error_message: 'Some tickets failed to re-route',
        });
        const promises = fp.map(row => batch.route(row.data), rows);
        console.log('Routing tickets');
        await Promise.all(promises);
        console.log('Flushing batch', batch);
        await batch.flush();
    };

    const handleBulkAutoRouteDisable = async rows => {
        const batch = ticketActions.batch({
            success_message: 'Auto-routing disabled for ticket(s)',
            error_message: 'Some tickets failed to update',
        });
        const promises = fp.pipe([
            fp.filter(row => {
                if (row.data.attributes.disable_auto_route) {
                    batch.error(
                        `Routing for ticket ${row.data.id} is already disabled`,
                    );
                    return false;
                }
                return true;
            }),
            fp.map(row => batch.disable_auto_route(row.data)),
        ])(rows);
        await Promise.all(promises);
        await batch.flush();
        refresh();
    };

    const handleBulkAutoRouteEnable = async rows => {
        const batch = ticketActions.batch({
            success_message: 'Auto-routing enabled for ticket(s)',
            error_message: 'Some tickets failed to update',
        });
        const promises = fp.pipe([
            fp.filter(row => {
                if (!row.data.attributes.disable_auto_route) {
                    batch.error(
                        `Routing fo ticket ${row.data.id} is already enabled`,
                    );
                    return false;
                }
                return true;
            }),
            fp.map(row => batch.enable_auto_route(row.data)),
        ])(rows);
        await Promise.all(promises);
        await batch.flush();
        refresh();
    };

    const handleBulkCreateProgressNote = async rows => {
        const batch = ticketActions.batch({
            success_message: 'Progress notes created',
            error_message: 'Some progress notes failed',
        });
        let errors = [];
        rows = fp.filter(row => {
            if (!row.data.attributes.data.orders) {
                errors.push(batch.error(`Ticket ${row.data.id} has no orders`));
                return false;
            }
            return true;
        }, rows);
        if (errors.length) {
            await Promise.all(errors);
        }
        if (!rows.length) {
            batch.flush();
            return;
        }
        const tickets = fp.map('data', rows);
        const ehrs = await uploadProgressNoteDialog(tickets);
        if (fp.isEmpty(ehrs)) {
            await ticketActions.error('No EHRs selected for upload');
            return;
        }
        console.log('handleBulkCreateProgressNote', {tickets, ehrs});
        await Promise.all(
            fp.map(row => {
                return batch.createProgressNote(row.data, ehrs);
            }, rows),
        );
        batch.flush();
        refresh();
    };

    const handleBulkTicketTypeChange = async rows => {
        const batch = ticketActions.batch({
            success_message: 'Ticket(s) updated.',
            error_message: 'Some tickets failed to update',
        });
        const ticketType = await ticketTypeSelect(rows[0]);
        const promises = fp.map(
            row => batch.setTicketType(row.data, ticketType),
            rows,
        );
        await Promise.all(promises);
        await batch.flush();
    };

    return (
        <dt2.DataTable
            id='table-tickets'
            title='Tickets'
            size='small'
            dataSource={tickets}
            options={{
                filtering: true,
                selection: true,
                search: true,
            }}
        >
            <dt2.Column
                id='col-ticket-id'
                title='Ticket ID'
                field='attributes.id_str'
                sorting={false}
                render={row => (
                    <Tooltip title={row.id}>
                        <R.Link
                            to={url_for('ticket', {
                                ticketid: row.id,
                            })}
                        >
                            {fp.truncate({length: 10}, row.id)}
                            {fp.get('attributes.priority', row.data) ===
                                'urgent' && (
                                <Flag fontSize='small' color='error' />
                            )}
                        </R.Link>
                    </Tooltip>
                )}
            />
            <dt2.Column
                id='col-submitted-on'
                title='Submitted on'
                field='attributes.data.created'
                type='date'
            />
            <dt2.Column
                id='col-submitted-by'
                title='Submitted by'
                field='attributes.data.submitter_name'
                FilterComponent={SubmitterFilter}
            />
            <dt2.Column
                id='col-patient-first'
                title='Patient First'
                field='attributes.data.patient_first'
            />
            <dt2.Column
                id='col-patient-last'
                title='Patient Last'
                field='attributes.data.patient_last'
            />
            <FacilityColumn field='attributes.data.facility_label' />
            <dt2.Column
                id='col-ticket-type'
                title='Ticket Type'
                field='attributes.data.ticket_type'
                lookup={ticketTypes}
            />
            <dt2.Column
                id='col-owner-name'
                title='Owner'
                field='attributes.data.owner_name'
                FilterComponent={OwnerFilter}
            />
            <dt2.Column
                id='col-provider-name'
                title='Provider'
                field='attributes.data.provider_name'
                FilterComponent={ProviderFilter}
            />
            <dt2.Column
                id='col-status'
                title='Status'
                render={rowData => <Status rowData={rowData} />}
                FilterComponent={StatusFilter}
                sorting={false}
            />
            <dt2.Action
                // Uploads to PCC & linked patient EHRs (practice fusion)
                name='upload-progress-note'
                tooltip='Create Progress Note(s)'
                icon={
                    <Chip
                        icon={<Assignment />}
                        label='Create Progress Notes'
                        color='secondary'
                    />
                }
                onClick={(evt, row) => handleBulkCreateProgressNote(row)}
            />
            <dt2.Action
                id='unarchive-btn-tickets-table'
                name='unarchive'
                tooltip='Un-archive ticket(s)'
                icon='unarchive'
                onClick={(evt, row) => handleBulkUnarchive(row)}
            />
            <dt2.Action
                name='archive'
                tooltip='Archive ticket(s)'
                icon='archive'
                onClick={(evt, row) => handleBulkArchive(row)}
            />
            <dt2.Action
                name='urgent'
                tooltip='Toggle urgent status'
                icon='flag'
                onClick={(evt, row) => handleBulkUrgent(row)}
            />
            <dt2.Action
                name='reroute'
                tooltip='Re-route ticket(s)'
                icon='directions'
                onClick={(evt, row) => handleBulkRoute(row)}
            />
            <dt2.Action
                name='disableAutoRoute'
                tooltip='Disable auto-routing for ticket(s)'
                icon='stop_screen_share'
                onClick={(evt, row) => handleBulkAutoRouteDisable(row)}
            />
            <dt2.Action
                name='enableAutoRoute'
                tooltip='Enable auto-routing for ticket(s)'
                icon='screen_share'
                onClick={(evt, row) => handleBulkAutoRouteEnable(row)}
            />
            <dt2.Action
                name='changeTicketType'
                tooltip='Change ticket type'
                icon='label'
                onClick={(evt, row) => handleBulkTicketTypeChange(row)}
            />
            <dt2.Action
                name='refresh'
                free
                onClick={() => tickets.fetch()}
                tooltip='Refresh'
                icon='refresh'
            />
        </dt2.DataTable>
    );
}
