import React from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';

import fp from 'lodash/fp';
import {Button} from '@material-ui/core';
import {AssignmentTurnedIn} from '@material-ui/icons';

import {useApi, ducks} from '@arborian/narrf';
import {url_for} from 'ccm/routes';
import {useDialog} from 'ccm/components/dialogs';

import TicketAcknowledgeDialog from 'ccm/components/dialogs/TicketAcknowledgeDialog';

const newNotificationSession = async (api, history, ticket, patient) => {
    // Create a session from patient data
    const url = api.url_for('ticketsession.TicketSessionCollection');
    const session = await api.fetchJsonApi(url, {
        method: 'POST',
        json: {data: patient},
    });

    // Modify the session form data
    const originalTicket = {
        id: ticket.id,
        ticket_type: ticket.attributes.data.ticket_type,
        orders: ticket.attributes.data.orders,
    };

    let sessionUpdate = {
        id: session.data.id,
        type: session.data.type,
        attributes: {
            ...fp.get('data.attributes', session),
            formid: '3',
            session_data: {
                ...fp.get('data.attributes.session_data', session),
                originalTicket,
            },
        },
    };

    const ackSession = await api.fetchJsonApi(session.links.self, {
        method: 'PUT',
        json: {data: sessionUpdate},
    });
    const enterFormFlow = url_for('dynaform', {
        sessionid: ackSession.data.id,
        formid: ackSession.data.attributes.formid,
        pageid: ackSession.data.attributes.next_page,
    });
    history.push(enterFormFlow);
};

export default function Acknowledge({ticket, actions}) {
    const api = useApi();
    const history = useHistory();
    const acknowledgeDialog = useDialog(TicketAcknowledgeDialog);
    const patient = useSelector(
        ducks.jsonapi.selectObject([
            'Patient',
            fp.get('relationships.patient.data.id', ticket),
        ]),
    );

    const handleAcknowledge = async () => {
        const values = await acknowledgeDialog(ticket);
        console.log({values});
        if (values) {
            await actions.acknowledge(ticket, {comments: values.comments});
            if (fp.get('createTicket', values)) {
                await newNotificationSession(api, history, ticket, patient);
            } else {
                actions.refresh(ticket);
            }
        } else {
            console.log('Acknowledge cancelled');
        }
    };

    if (
        !fp.includes(
            'Ticket.ACKNOWLEDGE',
            fp.get('meta.allowed_actions', ticket),
        )
    )
        return null;
    return (
        <Button
            variant='contained'
            color='primary'
            startIcon={<AssignmentTurnedIn />}
            onClick={handleAcknowledge}
        >
            Acknowledge (close) Ticket
        </Button>
    );
}
