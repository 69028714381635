import {useMemo} from 'react';
import fp from 'lodash/fp';

import useResources from './resources';

export {default as useListField} from './listField';
export {default as usePatientMapping} from './patientMapping';
export {default as useGroupMembers} from './groupMembers';

export {useResources};

const PRACTICE_DEFAULT_FETCH_OPTIONS = {
    sort: {field: 'attributes.name', direction: 'asc'},
    page: {number: 0, size: 20},
};

const FACILITY_DEFAULT_FETCH_OPTIONS = {
    sort: {field: 'attributes.name', direction: 'asc'},
    filter: {
        'attributes.active': true,
    },
    page: {number: 0, size: 20},
};

const PATIENT_DEFAULT_FETCH_OPTIONS = {
    sort: {field: 'attributes.name.last', direction: 'asc'},
    filter: {
        'attributes.active': true,
        'attributes.primary_patient_id': null,
    },
    page: {number: 0, size: 20},
};

const TICKET_DEFAULT_FETCH_OPTIONS = {
    sort: {field: 'attributes.data.created', direction: 'desc'},
    filter: {'attributes.archived': false},
    page: {number: 0, size: 20},
};

const MESSAGE_DEFAULT_FETCH_OPTIONS = {
    sort: {field: 'attributes.timestamp', direction: 'desc'},
    page: {number: 0, size: 20},
};

const HISTORY_DEFAULT_FETCH_OPTIONS = {
    sort: {field: 'attributes.created', direction: 'desc'},
    page: {number: 0, size: 20},
};

const EVENT_DEFAULT_FETCH_OPTIONS = {
    sort: {field: 'attributes.timestamp', direction: 'desc'},
    page: {number: 0, size: 20},
};

const OBSERVATION_DEFAULT_FETCH_OPTIONS = {
    page: {number: 0, size: 20},
    sort: {field: 'attributes.effectiveDateTime', direction: 'desc'},
};

const CONDITION_DEFAULT_FETCH_OPTIONS = {
    page: {number: 0, size: 20},
    filter: {'attributes.clinicalStatus.coding.0.code': ['active']},
};

const MEDICATION_DEFAULT_FETCH_OPTIONS = {
    page: {number: 0, size: 20},
    filter: {'attributes.status': ['ACTIVE']},
};

const ALLERGY_DEFAULT_FETCH_OPTIONS = {
    page: {number: 0, size: 20},
    filter: {'attributes.clinicalStatus.text': ['ACTIVE']},
};

const IMMUNIZATION_DEFAULT_FETCH_OPTIONS = {
    page: {number: 0, size: 20},
    sort: {field: 'attributes.raw.createdDateTime', direction: 'desc'},
};

const DIAGNOSTIC_REPORT_DEFAULT_FETCH_OPTIONS = {
    page: {number: 0, size: 20},
    sort: {field: 'attributes.effectiveDateTime', direction: 'desc'},
};

const CARE_PLAN_DEFAULT_FETCH_OPTIONS = {
    page: {number: 0, size: 20},
    sort: {field: 'attributes.createdDate', direction: 'desc'},
};

const PRACTITIONER_DEFAULT_FETCH_OPTIONS = {
    page: {number: 0, size: 20},
    sort: {field: 'attributes.name.last', direction: 'asc'},
};

const CONTACT_DEFAULT_FETCH_OPTIONS = {
    page: {number: 0, size: 20},
    sort: {field: 'attributes.name.last', direction: 'asc'},
};

const PROGRESS_NOTE_DEFAULT_FETCH_OPTIONS = {
    page: {number: 0, size: 20},
    sort: {field: 'attributes.raw.effectiveDate', direction: 'desc'},
};

const ASSESSMENT_DEFAULT_FETCH_OPTIONS = {
    page: {number: 0, size: 20},
    sort: {field: 'attributes.raw.assessmentRefDate', direction: 'desc'},
};

const NUTRITION_DEFAULT_FETCH_OPTIONS = {
    page: {number: 0, size: 20},
    sort: {field: 'attributes.startDateTime', direction: 'desc'},
};

const THERAPY_DEFAULT_FETCH_OPTIONS = {
    page: {number: 0, size: 20},
    sort: {field: 'attributes.progress.timestamp', direction: 'desc'},
};

const FILES_DEFAULT_FETCH_OPTIONS = {
    page: {number: 0, size: 5},
    sort: {field: 'attributes.file.uploadDate', direction: 'desc'},
};

const VISITS_DEFAULT_FETCH_OPTIONS = {
    page: {number: 0, size: 200},
    sort: {field: 'attributes.visit_date'},
};

const EVENTLOG_DEFAULT_FETCH_OPTIONS = {
    sort: {field: 'attributes._id', direction: 'desc'},
    filter: {'attributes.context.method': ['PUT', 'PATCH', 'POST', 'DELETE']},
    page: {number: 0, size: 20},
};

const CODEVALUE_DEFAULT_FETCH_OPTIONS = {
    sort: {field: 'attributes._id', direction: 'desc'},
    page: {number: 0, size: 20},
};

const CMS_FACILITIES_DEFAULT_FETCH_OPTIONS = {
    filter: {'attributes.facility_id': {$ne: null}},
    page: {number: 0, size: 20},
};

const EHR_DEFAULT_FETCH_OPTIONS = {
    page: {number: 0, size: 20},
};

const TICKET_TYPES_DEFAULT_FETCH_OPTIONS = {
    page: {number: 0, size: 20},
};

export function useUsers(props) {
    return useResources({
        link: 'user.UserCollection',
        prefix: 'user.',
        ...props,
    });
}

export function useGroups(props) {
    return useResources({
        link: 'group.GroupCollection',
        prefix: 'group.',
        ...props,
    });
}

export function usePractices(props) {
    return useResources({
        link: 'practice.PracticeCollection',
        prefix: 'practice.',
        initialFetchOptions: PRACTICE_DEFAULT_FETCH_OPTIONS,
        ...props,
    });
}

export function usePatients(props) {
    return useResources({
        link: 'patient.PatientCollection',
        prefix: 'pt.',
        initialFetchOptions: PATIENT_DEFAULT_FETCH_OPTIONS,
        ...props,
    });
}

export function useAllPatients(props) {
    const fetch_options = useMemo(
        () => ({
            sort: {field: 'attributes.name.last', direction: 'asc'},
            filter: {
                // Does not filter inactive patients
                'attributes.primary_patient_id': null,
            },
            page: {number: 0, size: 20},
        }),
        [],
    );

    return useResources({
        link: 'patient.PatientCollection',
        prefix: 'pt.',
        initialFetchOptions: fetch_options,
        ...props,
    });
}

export function useFacilities(props) {
    return useResources({
        link: 'facility.FacilityCollection',
        prefix: 'fac.',
        initialFetchOptions: FACILITY_DEFAULT_FETCH_OPTIONS,
        ...props,
    });
}

export function useTickets(props) {
    return useResources({
        link: 'ticket.TicketCollection',
        prefix: 'tkt.',
        initialFetchOptions: TICKET_DEFAULT_FETCH_OPTIONS,
        ...props,
    });
}

export function useMyTickets(props) {
    return useResources({
        link: 'ticket.MyTicketCollection',
        prefix: 'tkt.',
        initialFetchOptions: TICKET_DEFAULT_FETCH_OPTIONS,
        ...props,
    });
}

export function useMessages(patient, props) {
    return useRelated(patient, 'messages', {
        prefix: 'msg.',
        initialFetchOptions: MESSAGE_DEFAULT_FETCH_OPTIONS,
        ...props,
    });
}

export function useTicketHistory(ticket, props) {
    return useRelated(ticket, 'history', {
        prefix: 'hist.',
        initialFetchOptions: HISTORY_DEFAULT_FETCH_OPTIONS,
        ...props,
    });
}

export function useTicketEvents(ticket, props) {
    return useRelated(ticket, 'events', {
        prefix: 'evt.',
        initialFetchOptions: EVENT_DEFAULT_FETCH_OPTIONS,
        ...props,
    });
}

export function useRelated(resource, rel, props) {
    return useResources({
        prefix: `res-${rel}.`,
        href: fp.get(['relationships', rel, 'links', 'related'], resource),
        ...props,
    });
}

export function useObservations(patient, props) {
    return useRelated(patient, 'observations', {
        prefix: 'pt-observation.',
        initialFetchOptions: OBSERVATION_DEFAULT_FETCH_OPTIONS,
        ...props,
    });
}

export function useConditions(patient, props) {
    return useRelated(patient, 'conditions', {
        prefix: 'pt-condition.',
        initialFetchOptions: CONDITION_DEFAULT_FETCH_OPTIONS,
        ...props,
    });
}

export function useMedications(patient, props) {
    return useRelated(patient, 'medications', {
        prefix: 'pt-med.',
        initialFetchOptions: MEDICATION_DEFAULT_FETCH_OPTIONS,
        ...props,
    });
}

export function useAllergies(patient, props) {
    return useRelated(patient, 'allergies', {
        prefix: 'pt-allergy.',
        initialFetchOptions: ALLERGY_DEFAULT_FETCH_OPTIONS,
        ...props,
    });
}

export function useImmunizations(patient, props) {
    return useRelated(patient, 'immunizations', {
        prefix: 'pt-immunization.',
        initialFetchOptions: IMMUNIZATION_DEFAULT_FETCH_OPTIONS,
        ...props,
    });
}

export function useDiagnosticReports(patient, props) {
    return useRelated(patient, 'diagnostic_reports', {
        prefix: 'pt-lab.',
        initialFetchOptions: DIAGNOSTIC_REPORT_DEFAULT_FETCH_OPTIONS,
        ...props,
    });
}

export function useCarePlans(patient, props) {
    return useRelated(patient, 'care_plans', {
        prefix: 'pt-careplan.',
        initialFetchOptions: CARE_PLAN_DEFAULT_FETCH_OPTIONS,
        ...props,
    });
}

export function usePractitioners(patient, props) {
    return useRelated(patient, 'practitioners', {
        prefix: 'pt-md.',
        initialFetchOptions: PRACTITIONER_DEFAULT_FETCH_OPTIONS,
        ...props,
    });
}

export function useContacts(patient, props) {
    return useRelated(patient, 'contacts', {
        prefix: 'pt-contact.',
        initialFetchOptions: CONTACT_DEFAULT_FETCH_OPTIONS,
        ...props,
    });
}

export function useProgressNotes(patient, props) {
    return useRelated(patient, 'progress_notes', {
        prefix: 'pt-progressnote.',
        initialFetchOptions: PROGRESS_NOTE_DEFAULT_FETCH_OPTIONS,
        ...props,
    });
}

export function useAssessments(patient, props) {
    return useRelated(patient, 'assessments', {
        prefix: 'pt-assessment.',
        initialFetchOptions: ASSESSMENT_DEFAULT_FETCH_OPTIONS,
        ...props,
    });
}

export function useNutrition(patient, props) {
    return useRelated(patient, 'nutrition_orders', {
        prefix: 'pt-nutrition.',
        initialFetchOptions: NUTRITION_DEFAULT_FETCH_OPTIONS,
        ...props,
    });
}

export function useTherapies(patient, props) {
    return useRelated(patient, 'therapies', {
        prefix: 'pt-therapy.',
        initialFetchOptions: THERAPY_DEFAULT_FETCH_OPTIONS,
        type: 'Therapy',
        ...props,
    });
}

export function useFiles(patient, props) {
    return useRelated(patient, 'files', {
        prefix: 'pt-files.',
        initialFetchOptions: FILES_DEFAULT_FETCH_OPTIONS,
        type: 'PatientFile',
        ...props,
    });
}

export function useAllVisits(props) {
    return useResources({
        link: 'visit.VisitCollection',
        prefix: 'visit.',
        initialFetchOptions: VISITS_DEFAULT_FETCH_OPTIONS,
        ...props,
    });
}

export function useAllUpcomingVisits(props) {
    return useResources({
        link: 'visit.UpcomingVisits',
        prefix: 'visit.',
        initialFetchOptions: VISITS_DEFAULT_FETCH_OPTIONS,
        ...props,
    });
}

export function useFacilityUpcomingVisits(facility, props) {
    return useRelated(facility, 'upcomingVisits', {
        prefix: 'fac-visits.',
        initialFetchOptions: VISITS_DEFAULT_FETCH_OPTIONS,
        type: 'Visit',
        ...props,
    });
}

export function usePracticeUpcomingVisits(practice, props) {
    return useRelated(practice, 'upcomingVisits', {
        prefix: 'practice-visits.',
        initialFetchOptions: VISITS_DEFAULT_FETCH_OPTIONS,
        type: 'Visit',
        ...props,
    });
}

export function useProviderUpcomingVisits(provider, props) {
    return useRelated(provider, 'upcomingVisits', {
        prefix: 'provider-visits.',
        initialFetchOptions: VISITS_DEFAULT_FETCH_OPTIONS,
        type: 'Visit',
        ...props,
    });
}

export function usePatientUpcomingVisits(patient, props) {
    return useRelated(patient, 'upcomingVisits', {
        prefix: 'patient-visits.',
        initialFetchOptions: VISITS_DEFAULT_FETCH_OPTIONS,
        type: 'Visit',
        ...props,
    });
}

export function useEventLog(props) {
    return useResources({
        link: 'eventLog.Collection',
        prefix: 'event.',
        initialFetchOptions: EVENTLOG_DEFAULT_FETCH_OPTIONS,
        ...props,
    });
}

export function useCodeRange(practice, props) {
    return useRelated(practice, 'code_ranges', {
        prefix: 'range.',
        initialFetchOptions: CODEVALUE_DEFAULT_FETCH_OPTIONS,
        type: 'ValueRange',
        ...props,
    });
}

export function useCMSFacilities(props) {
    return useResources({
        link: 'cms.CMSFacilityCollection',
        prefix: 'cmsTable.',
        initialFetchOptions: CMS_FACILITIES_DEFAULT_FETCH_OPTIONS,
        ...props,
    });
}

export function useEhrs(props) {
    return useResources({
        link: 'ehr.Collection',
        prefix: 'ehrTable.',
        initialFetchOptions: EHR_DEFAULT_FETCH_OPTIONS,
        ...props,
    });
}

export function useTicketTypes(props) {
    return useResources({
        link: 'ticketType.Collection',
        prefix: 'ticketType.',
        initialFetchOptions: TICKET_TYPES_DEFAULT_FETCH_OPTIONS,
        ...props,
    });
}
